import { Fragment } from 'react';
import { Combobox, Dialog, Transition } from '@headlessui/react';

import { DocumentIcon, PhotoIcon, VideoCameraIcon, BookOpenIcon, WrenchIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ');
}
export default function Actions({ open, setOpen, setType }: { open: boolean; setOpen: any; setType: any }) {
	const navigation = useNavigate();
	const items = [
		{
			id: 1,
			name: 'Article',
			description: 'Add freeform text with basic formatting options.',
			fun: () => navigation('/article'),
			color: 'bg-green-500',
			icon: DocumentIcon
		},
		{
			id: 1,
			name: 'Video',
			description: 'Add freeform text with basic formatting options.',
			fun: () => {
				setOpen(false);
				setType('video');
			},
			color: 'bg-blue-500',
			icon: VideoCameraIcon
		},
		{
			id: 1,
			name: 'Image',
			description: 'Add freeform text with basic formatting options.',
			fun: () => {
				setOpen(false);
				setType('image');
			},
			color: 'bg-red-500',
			icon: PhotoIcon
		},
		{
			id: 1,
			name: 'Book',
			description: 'Add freeform text with basic formatting options.',
			fun: () => {
				setOpen(false);
				setType('book');
			},
			color: 'bg-yellow-500',
			icon: BookOpenIcon
		},
		{
			id: 1,
			name: 'Tools',
			description: 'Add freeform text with basic formatting options.',
			fun: () => {
				setOpen(false);
				setType('tool');
			},
			color: 'bg-indigo-500',
			icon: WrenchIcon
		}
	];

	return (
		<Transition.Root show={open} as={Fragment} appear>
			<Dialog as='div' className='relative z-[10000]' onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity' />
				</Transition.Child>

				<div className='fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20'>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0 scale-95'
						enterTo='opacity-100 scale-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100 scale-100'
						leaveTo='opacity-0 scale-95'>
						<Dialog.Panel className='mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all'>
							<Combobox onChange={(item) => console.log(item)}>
								{items.length > 0 && (
									<Combobox.Options static className='max-h-96 scroll-py-3 overflow-y-auto p-3 '>
										{items.map((item) => (
											<Combobox.Option
												key={item.id}
												value={item}
												className={({ active }) =>
													classNames('flex  select-none rounded-xl p-3 cursor-pointer', active && 'bg-gray-100')
												}>
												{({ active }) => (
													<button onClick={item.fun} className='flex items-start justify-start text-start'>
														<div
															className={classNames(
																'flex h-10 w-10 flex-none items-center justify-center rounded-lg',
																item.color
															)}>
															<item.icon className='h-6 w-6 text-white' aria-hidden='true' />
														</div>
														<div className='ml-4 flex-auto'>
															<p className={classNames('text-sm font-medium', active ? 'text-gray-900' : 'text-gray-700')}>
																{item.name}
															</p>
															<p className={classNames('text-sm', active ? 'text-gray-700' : 'text-gray-500')}>
																{item.description}
															</p>
														</div>
													</button>
												)}
											</Combobox.Option>
										))}
									</Combobox.Options>
								)}
							</Combobox>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
