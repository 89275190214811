import { $createLinkNode } from '@lexical/link';
import { $createListItemNode, $createListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import * as React from 'react';
import './index.css';
import './setupEnv';
import { isDevPlayground } from './appSettings';
import { SettingsContext, useSettings } from './context/SettingsContext';
import { SharedAutocompleteContext } from './context/SharedAutocompleteContext';
import { SharedHistoryContext } from './context/SharedHistoryContext';
import Editor from './Editor';
import logo from './images/logo.png';
import PlaygroundNodes from './nodes/PlaygroundNodes';
import DocsPlugin from './plugins/DocsPlugin';
import PasteLogPlugin from './plugins/PasteLogPlugin';
import { TableContext } from './plugins/TablePlugin';
import TestRecorderPlugin from './plugins/TestRecorderPlugin';
import TypingPerfPlugin from './plugins/TypingPerfPlugin';
import Settings from './Settings';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import '@fortawesome/fontawesome-free/css/all.css';
import DownloadHTML from '../Download/index';

function prepopulatedRichText() {
	const root = $getRoot();
	if (root.getFirstChild() === null) {
		const heading = $createHeadingNode('h1');
		heading.append($createTextNode('Welcome to the playground'));
		root.append(heading);
		const quote = $createQuoteNode();
		quote.append(
			$createTextNode(
				`In case you were wondering what the black box at the bottom is – it's the debug view, showing the current state of the editor. ` +
					`You can disable it by pressing on the settings control in the bottom-left of your screen and toggling the debug view setting.`
			)
		);
		root.append(quote);
		const paragraph = $createParagraphNode();
		paragraph.append(
			$createTextNode('The playground is a demo environment built with '),
			$createTextNode('@lexical/react').toggleFormat('code'),
			$createTextNode('.'),
			$createTextNode(' Try typing in '),
			$createTextNode('some text').toggleFormat('bold'),
			$createTextNode(' with '),
			$createTextNode('different').toggleFormat('italic'),
			$createTextNode(' formats.')
		);
		root.append(paragraph);
		const paragraph2 = $createParagraphNode();
		paragraph2.append($createTextNode('Make sure to check out the various plugins in the toolbar. You can also use #hashtags or @-mentions too!'));
		root.append(paragraph2);
		const paragraph3 = $createParagraphNode();
		paragraph3.append($createTextNode(`If you'd like to find out more about Lexical, you can:`));
		root.append(paragraph3);
		const list = $createListNode('bullet');
		list.append(
			$createListItemNode().append(
				$createTextNode(`Visit the `),
				$createLinkNode('https://lexical.dev/').append($createTextNode('Lexical website')),
				$createTextNode(` for documentation and more information.`)
			),
			$createListItemNode().append(
				$createTextNode(`Check out the code on our `),
				$createLinkNode('https://github.com/facebook/lexical').append($createTextNode('GitHub repository')),
				$createTextNode(`.`)
			),
			$createListItemNode().append(
				$createTextNode(`Playground code can be found `),
				$createLinkNode('https://github.com/facebook/lexical/tree/main/packages/lexical-playground').append($createTextNode('here')),
				$createTextNode(`.`)
			),
			$createListItemNode().append(
				$createTextNode(`Join our `),
				$createLinkNode('https://discord.com/invite/KmG4wQnnD9').append($createTextNode('Discord Server')),
				$createTextNode(` and chat with the team.`)
			)
		);
		root.append(list);
		const paragraph4 = $createParagraphNode();
		paragraph4.append(
			$createTextNode(
				`Lastly, we're constantly adding cool new features to this playground. So make sure you check back here when you next get a chance :).`
			)
		);
		root.append(paragraph4);
	}
}

function MarkDown(): JSX.Element {
	const [isModelOpened, setModelOpened] = React.useState<boolean>(false);
	const {
		settings: { isCollab, emptyEditor, measureTypingPerf }
	} = useSettings();

	const initialConfig = {
		editorState: isCollab ? null : emptyEditor ? undefined : prepopulatedRichText,
		namespace: 'Playground',
		nodes: [...PlaygroundNodes],
		onError: (error: Error) => {
			throw error;
		},
		theme: PlaygroundEditorTheme
	};
	const handleToggleModel = () => {
		setModelOpened((opened) => !opened);
	};

	return (
		<LexicalComposer initialConfig={initialConfig}>
			<SharedHistoryContext>
				<TableContext>
					<SharedAutocompleteContext>
						<header>
							<a href='https://www.masader-tech.com' target='_blank' rel='noreferrer'>
								<img src={logo} alt='Masader Logo' />
							</a>
						</header>
						<div className='editor-shell'>
							<Editor handleToggleModel={handleToggleModel} isModelOpened={isModelOpened} />
						</div>

						<Settings handleOnClick={handleToggleModel} />
						<DownloadHTML />
						{isDevPlayground ? <DocsPlugin /> : null}
						{isDevPlayground ? <PasteLogPlugin /> : null}
						{isDevPlayground ? <TestRecorderPlugin /> : null}

						{measureTypingPerf ? <TypingPerfPlugin /> : null}
					</SharedAutocompleteContext>
				</TableContext>
			</SharedHistoryContext>
		</LexicalComposer>
	);
}

export default function MarkdownApp(): JSX.Element {
	const token = localStorage.getItem('token');
	React.useEffect(() => {
		if (!token || token !== 'PsyXBPdWm1emhErKRCuxvaBo3Ov1') {
			localStorage.clear();
			window.location.reload();
		}
	}, [token]);
	return (
		<SettingsContext>
			<MarkDown />
			<a href='https://www.masader-tech.com' target='_blank' rel='noreferrer' aria-label='View source on GitHub'>
				<svg
					width='80'
					height='80'
					viewBox='0 0 250 250'
					style={{
						border: '0',
						color: '#eee',
						fill: '#222',
						left: '0',
						position: 'absolute',
						top: '0',
						transform: 'scale(-1,1)'
					}}
					aria-hidden='true'>
					<i className='fa-solid fa-globe' style={{ color: 'white' }}></i>
					<path d='M0,0 L115,115 L130,115 L142,142 L250,250 L250,0 Z' />
				</svg>
			</a>
		</SettingsContext>
	);
}
