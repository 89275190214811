import React, { useState } from 'react';
import { $generateHtmlFromNodes } from '@lexical/html';
import { uuidv4 } from '@firebase/util';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
type Props = {};

const DownloadHTML = (props: Props) => {
	const [editor] = useLexicalComposerContext();
	const [href, setHref] = useState<string>('');
	const handleOnClick = () => {
		editor.update(() => {
			const htmlString = $generateHtmlFromNodes(editor, null);
			const file = new Blob([htmlString], { type: 'text/html' });

			setHref(URL.createObjectURL(file));
		});
	};

	return (
		<button onClick={handleOnClick} className='w-6/12 h-20 rounded-lg bg-white m-auto text-2xl flex items-center justify-center my-10 text-gray-800'>
			<a download={`${uuidv4()}.html`} href={href} target='_blank' rel='noreferrer'>
				{' '}
				Download HTML
			</a>
		</button>
	);
};

export default DownloadHTML;
