import { FormikProps } from 'formik';
import React from 'react';

interface InputProps {
	name: string;
	label: string;
	placeholder: string;
	formik: FormikProps<any>;
}

const Input: React.FC<InputProps> = ({ label, name, placeholder, formik }) => {
	return (
		<div className='flex flex-col gap-2 w-full h-fit px-4 py-3 rounded-lg  bg-[#363636ef] '>
			<label htmlFor={name} className='text-white'>
				{label}
			</label>
			<input
				style={{ boxShadow: 'none', outline: 'none', border: 'none' }}
				id={name}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values[name]}
				placeholder={placeholder}
				type='text'
				className='shadow-none rounded-lg outline-none text-[#ffffffc9] placeholder-[#FFFFFF66] border-none w-full h-full bg-transparent focus:shadow-none'
				name={name}
			/>
		</div>
	);
};
export default Input;
