import { useFormik } from 'formik';
import React from 'react';
import { uuidv4 } from '@firebase/util';
import Input from 'components/markdown/shared/input';
import { doc, setDoc } from 'firebase/firestore';
import { db } from 'firebase.init';

type Props = {
	handleToggleModel: () => void;
};

const VideoForm: React.FC<Props> = ({ handleToggleModel }) => {
	const formik = useFormik({
		initialValues: {
			id: uuidv4(),
			createdAt: new Date().getTime(),
			videoURL: '',
			title: '',
			description: '',
			tags: '',
			thumbnailURL: '',
			lang: ''
		},
		onSubmit(values, formikHelpers) {
			if (values.lang === 'ar') {
				setDoc(doc(db, 'ArabicVideos', values.id), { ...values })
					.then((res) => {
						formikHelpers.resetForm();
						handleToggleModel();
					})
					.catch((error) => {
						console.log('error', error);
						handleToggleModel();
					});
			} else {
				setDoc(doc(db, 'EnglishVideos', values.id), { ...values })
					.then((res) => {
						formikHelpers.resetForm();
						handleToggleModel();
					})
					.catch((error) => {
						console.log('error', error);
						handleToggleModel();
					});
			}
		}
	});
	return (
		<form onSubmit={formik.handleSubmit} className='flex flex-col items-center justify-center gap-5'>
			<Input formik={formik} label='Video Title' placeholder='Ui Ux Design important for your website ...' name='title' />
			<Input formik={formik} label='Video description' placeholder='Ui Ux Design important for your website ...' name='description' />
			<Input formik={formik} label='Video Tags' placeholder='Ui Ux, Front End Development, Backend, servers....etc' name='tags' />
			<Input formik={formik} label='Video Link' placeholder='https://www.youtube.com/watch?v=8h9xkeqyw3g' name='videoURL' />
			<Input
				formik={formik}
				label='Thumbnail Image Link'
				placeholder='data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27336%27%20height=%27347%27/%3e'
				name='thumbnailURL'
			/>
			<Input formik={formik} label='Video Lang' placeholder='en, ar' name='lang' />

			<button type='submit' className='w-1/2 bg-green-600 text-white h-10 rounded-lg flex items-center justify-center m-auto my-10'>
				Create Video
			</button>
		</form>
	);
};

export default VideoForm;
