import Input from 'components/markdown/shared/input';
import { useFormik } from 'formik';
import React from 'react';
import { uuidv4 } from '@firebase/util';
import { doc, setDoc } from 'firebase/firestore';
import { db } from 'firebase.init';

type Props = {
	handleToggleModel: () => void;
};
const ToolForm: React.FC<Props> = ({ handleToggleModel }) => {
	const formik = useFormik({
		initialValues: {
			id: uuidv4(),
			createdAt: new Date().getTime(),
			toolURL: '',
			title: '',
			description: '',
			tags: '',
			thumbnailURL: ''
		},
		onSubmit(values, formikHelpers) {
			setDoc(doc(db, 'Tools', values.id), { ...values })
				.then((res) => {
					formikHelpers.resetForm();
					handleToggleModel();
				})
				.catch((error) => {
					console.log('error', error);
					handleToggleModel();
				});
		}
	});
	return (
		<form onSubmit={formik.handleSubmit} className='flex flex-col items-center justify-center gap-5'>
			<Input formik={formik} label='Tool Title' placeholder='Ui Ux Design important for your website ...' name='title' />
			<Input formik={formik} label='Tool description' placeholder='Ui Ux Design important for your website ...' name='description' />
			<Input formik={formik} label='Tool Tags' placeholder='Ui Ux, Front End Development, Backend, servers....etc' name='tags' />
			<Input formik={formik} label='Tool Link' placeholder='https://www.youtube.com/watch?v=8h9xkeqyw3g' name='toolURL' />
			<Input
				formik={formik}
				label='Thumbnail Image Link'
				placeholder='data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27336%27%20height=%27347%27/%3e'
				name='thumbnailURL'
			/>

			<button type='submit' className='w-1/2 bg-green-600 text-white h-10 rounded-lg flex items-center justify-center m-auto my-10'>
				Create Tool
			</button>
		</form>
	);
};

export default ToolForm;
