import * as React from 'react';
import { useMemo, useState } from 'react';

import { isDevPlayground } from './appSettings';
import { useSettings } from './context/SettingsContext';
import Switch from './ui/Switch';

export default function Settings({ handleOnClick }: { handleOnClick: () => void }): JSX.Element {
	const windowLocation = window.location;
	const {
		setOption,
		settings: {
			measureTypingPerf,
			isCollab,
			isRichText,
			isMaxLength,
			isCharLimit,
			isCharLimitUtf8,
			isAutocomplete,
			showTreeView,
			showNestedEditorTreeView,
			disableBeforeInput,
			showTableOfContents
		}
	} = useSettings();
	const [showSettings, setShowSettings] = useState(false);
	const [isSplitScreen, search] = useMemo(() => {
		const parentWindow = window.parent;
		const _search = windowLocation.search;
		const _isSplitScreen = parentWindow && parentWindow.location.pathname === '/split/';
		return [_isSplitScreen, _search];
	}, [windowLocation]);

	return (
		<>
			<button id='options-button' className={`editor-dev-button ${showSettings ? 'active' : ''}`} onClick={() => setShowSettings(!showSettings)} />
			<button
				className='w-6/12 h-20 rounded-lg bg-white m-auto text-2xl flex items-center justify-center my-10 text-gray-800'
				onClick={() => handleOnClick()}>
				Publish To Masader
			</button>
			{showSettings ? (
				<div className='switches'>
					{isRichText && isDevPlayground && (
						<Switch
							onClick={() => {
								setOption('isCollab', !isCollab);
								window.location.reload();
							}}
							checked={isCollab}
							text='Collaboration'
						/>
					)}
					{isDevPlayground && (
						<Switch
							onClick={() => {
								if (isSplitScreen) {
									window.parent.location.href = `/${search}`;
								} else {
									window.location.href = `/split/${search}`;
								}
							}}
							checked={isSplitScreen}
							text='Split Screen'
						/>
					)}
					<Switch onClick={() => setOption('measureTypingPerf', !measureTypingPerf)} checked={measureTypingPerf} text='Measure Perf' />
					<Switch onClick={() => setOption('showTreeView', !showTreeView)} checked={showTreeView} text='Debug View' />
					<Switch
						onClick={() => setOption('showNestedEditorTreeView', !showNestedEditorTreeView)}
						checked={showNestedEditorTreeView}
						text='Nested Editors Debug View'
					/>
					<Switch
						onClick={() => {
							setOption('isRichText', !isRichText);
							setOption('isCollab', false);
						}}
						checked={isRichText}
						text='Rich Text'
					/>
					<Switch onClick={() => setOption('isCharLimit', !isCharLimit)} checked={isCharLimit} text='Char Limit' />
					<Switch onClick={() => setOption('isCharLimitUtf8', !isCharLimitUtf8)} checked={isCharLimitUtf8} text='Char Limit (UTF-8)' />
					<Switch onClick={() => setOption('isMaxLength', !isMaxLength)} checked={isMaxLength} text='Max Length' />
					<Switch onClick={() => setOption('isAutocomplete', !isAutocomplete)} checked={isAutocomplete} text='Autocomplete' />
					<Switch
						onClick={() => {
							setOption('disableBeforeInput', !disableBeforeInput);
							setTimeout(() => window.location.reload(), 500);
						}}
						checked={disableBeforeInput}
						text='Legacy Events'
					/>
					<Switch
						onClick={() => {
							setOption('showTableOfContents', !showTableOfContents);
						}}
						checked={showTableOfContents}
						text='Table Of Contents'
					/>
				</div>
			) : null}
		</>
	);
}
