import LoginForm from 'components/forms/LoginForm';
import MarkdownApp from 'components/markdown';
import Main from 'pages';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
type Props = {};

const App = (props: Props) => {
	const token = localStorage.getItem('token');
	useEffect(() => {}, [token]);
	if (token && token === 'PsyXBPdWm1emhErKRCuxvaBo3Ov1') {
		return (
			<Routes>
				<Route path='/' element={<Main />} />
				<Route path='/article' element={<MarkdownApp />} />
				<Route path='*' element={<Navigate to={'/'} />} />
			</Routes>
		);
	}
	return (
		<Routes>
			<Route path='*' element={<Navigate to={'/login'} />} />
			<Route path='/login' element={<LoginForm />} />
		</Routes>
	);
};

export default App;
