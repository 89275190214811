import { FormikProps } from 'formik';
import React from 'react';

import Input from './shared/input';

type Props = {
	formik: FormikProps<any>;
};

const ArticleForm: React.FC<Props> = ({ formik }) => {
	return (
		<form onSubmit={formik.handleSubmit} className='flex flex-col items-center justify-center gap-5'>
			<Input formik={formik} label='Article Title' placeholder='Ui Ux Design important for your website ...' name='title' />
			<Input formik={formik} label='Article Description' placeholder='Ui Ux, Front End Development, Backend, servers....etc' name='description' />
			<Input formik={formik} label='Article Tags' placeholder='Ui Ux, Front End Development, Backend, servers....etc' name='tags' />
			<Input
				formik={formik}
				label='Article Image Link'
				placeholder='data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27336%27%20height=%27347%27/%3e'
				name='articleImage'
			/>
			<Input formik={formik} label='Article Lang' placeholder='en, ar' name='lang' />

			<button type='submit' className='w-1/2 bg-green-600 text-white h-10 rounded-lg flex items-center justify-center m-auto my-10'>
				Create Article
			</button>
		</form>
	);
};

export default ArticleForm;
