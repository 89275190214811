import { BookOpenIcon } from '@heroicons/react/24/outline';
import Actions from 'components/Actions';
import BookForm from 'components/forms/BookForm';
import ToolForm from 'components/forms/ToolForm';
import VideoForm from 'components/forms/VideoForm';
import Modal from 'components/markdown/ui/Modal';
import React, { useState } from 'react';

type Props = {};

const Main = (props: Props) => {
	const [open, setOpen] = useState<boolean>(false);
	const [type, setType] = useState<string>('');
	const handleToggleModel = () => {
		setType('');
	};
	const token = localStorage.getItem('token');
	React.useEffect(() => {
		if (!token || token !== 'PsyXBPdWm1emhErKRCuxvaBo3Ov1') {
			localStorage.clear();
			window.location.reload();
		}
	}, [token]);
	const getForm = () => {
		switch (type) {
			case 'video':
				return <VideoForm handleToggleModel={handleToggleModel} />;
			case 'book':
				return <BookForm handleToggleModel={handleToggleModel} />;
			case 'tool':
				return <ToolForm handleToggleModel={handleToggleModel} />;

			default:
				return;
		}
	};
	return (
		<div>
			<div className='w-11.5/12 md:w-1/2 m-auto flex items-center justify-center h-screen'>
				<button
					onClick={() => setOpen((opened) => !opened)}
					type='button'
					className='relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
					<svg className='mx-auto h-12 w-12 text-gray-400' stroke='currentColor' fill='none' viewBox='0 0 48 48' aria-hidden='true'>
						<BookOpenIcon />
					</svg>
					<span className='mt-2 block text-sm font-semibold text-white'>Create a new Item</span>
				</button>
			</div>
			<Actions open={open} setOpen={setOpen} setType={setType} />
			{type.length ? (
				<Modal onClose={handleToggleModel} closeOnClickOutside={true} title={'Create New ' + type}>
					{getForm()}
				</Modal>
			) : null}
		</div>
	);
};

export default Main;
