import { useFormik } from 'formik';
import React from 'react';
import { auth } from 'firebase.init';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Input from 'components/markdown/shared/input';
import { useNavigate } from 'react-router-dom';

type Props = {};

const LoginForm: React.FC<Props> = () => {
	const formik = useFormik({
		initialValues: {
			email: '',
			password: ''
		},
		onSubmit(values, formikHelpers) {
			signInWithEmailAndPassword(auth, values.email, values.password).then((res) => {
				localStorage.setItem('token', res.user.uid);
				window.location.replace('/');
			});
		}
	});
	return (
		<div className='w-full h-screen flex items-center justify-center'>
			<form onSubmit={formik.handleSubmit} className='flex flex-col items-center justify-center gap-5 w-11/12 m-auto sm:w-1/2'>
				<Input formik={formik} label='Email Address' placeholder='email@example.com' name='email' />
				<Input formik={formik} label='password' placeholder='PaSsWoRd' name='password' />

				<button type='submit' className='w-1/2 bg-green-600 text-white h-10 rounded-lg flex items-center justify-center m-auto my-10'>
					Login
				</button>
			</form>
		</div>
	);
};

export default LoginForm;
